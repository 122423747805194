<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h1 class="ml-2" style="font-weight: 200">Elementos De Costos</h1>
        </div>
        <div>
          <h3 class="ml-2 mb-2" style="font-weight: 200">
            Insumos y Servicios
          </h3>
        </div>

        <div class="p-4 ml-2 ml-4">
          <code>
            {{ searchText }}
          </code>
          <v-text-field :disabled="tab == 0" v-model="searchText">
          </v-text-field>
        </div>

        <v-tabs
          class="my-5"
          v-model="tab"
          background-color="white"
          color="primary"
          center-active
          show-arrows
          centered
        >
          <v-tab class="text--secondary">Familias de elementos de Costo</v-tab>
          <span class="mt-4 mx-4">|</span>
          <v-tab
            v-for="family in costItemFamiliesSorted"
            :key="family.id"
            class="text--secondary"
            :class="{
              'muted-text': filteredDirectSliceCostItems.find(
                (el) => el.costItemFamilyName === family.name
              )
                ? false
                : true,
            }"
            >{{ family.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
              sort-by="naturalOrder"
              dense
              :options="{ itemsPerPage: 15 }"
              :headers="
                [
                  { name: 'Nombre', value: 'id' },
                  { name: 'Nombre', value: 'name' },
                  { name: 'Orden Natural', value: 'naturalOrder' },
                  { name: 'Autofinanciable', value: 'autofinanciable' },
                ].map((i) => {
                  return { text: i.name, value: i.value };
                })
              "
              :items="costItemFamiliesFiltered"
            ></v-data-table>
            <div class="d-flex mt-4">
              <v-text-field
                label="Semillas, Fertilizantes..."
                dense
                class="mr-2"
                v-model="newFamily.name"
              >
              </v-text-field>
              <v-text-field
                label="Orden"
                dense
                class="mr-2"
                type="number"
                v-model.number="newFamily.naturalOrder"
              >
              </v-text-field>

              <v-select
                label="Autofinanciable"
                v-model="newFamily.autofinanciable"
                class="mr-2"
                dense
                :value="{ value: 1, name: 'Si' }"
                :items="[
                  { value: 1, name: 'Si' },
                  { value: 0, name: 'No' },
                ]"
                item-text="name"
                item-value="value"
              >
              </v-select>

              <v-btn
                color="primary white--text"
                class="ml-2"
                @click="saveNewFamily"
                depressed
              >
                <v-icon> mdi-floppy </v-icon></v-btn
              >
            </div>
          </v-tab-item>
          <v-tab-item v-for="family in costItemFamiliesSorted" :key="family.id">
            <v-data-table
              dense
              :options="{ itemsPerPage: 15 }"
              :headers="costItemHeaders"
              :items="
                filteredDirectSliceCostItems.filter(
                  (el) => el.costItemFamilyName === family.name
                )
              "
              @click:row="navigateToCostItem"
              ><template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-3" @click.stop="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click.stop="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5 mb-2"
                          >Editar "{{ dialogTitle }}"
                        </span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <div
                            class="
                              d-flex
                              justify-center
                              pa-1
                              align-center
                              flex-column flex-lg-row flex-xl-row
                            "
                          >
                            <v-text-field
                              label="Nombre de elemento de costo"
                              dense
                              class="mr-2"
                              v-model="costItem.name"
                            >
                            </v-text-field>
                            <v-text-field
                              label="Unidad de Medida"
                              v-model="costItem.unitMeasure"
                              dense
                              class="mr-2"
                            >
                            </v-text-field>

                            <v-text-field
                              label="Familia de Elemento de Costo"
                              dense
                              filled
                              readonly
                              outlined
                              class="mr-2 mt-1"
                              type="text"
                              v-model="costItem.costItemFamilyName"
                            >
                            </v-text-field>
                            <v-text-field
                              label="Impuesto (0-100)%"
                              dense
                              append-icon="mdi-percent-outline"
                              class="mr-2"
                              type="number"
                              v-model.number="costItem.tax"
                            >
                            </v-text-field>
                          </div>
                          <div>
                            <v-select
                              label="Variantes Regionales"
                              v-model="costItem.hasRegionVariants"
                              class="mr-2"
                              dense
                              outlined
                              filled
                              readonly
                              :items="[
                                { text: 'Si', value: true },
                                { text: 'No', value: false },
                              ]"
                              item-text="text"
                              item-value="value"
                            >
                            </v-select>

                            <v-select
                              label="Variantes de Cultivo"
                              v-model="costItem.hasActivityVariants"
                              class="mr-2"
                              dense
                              outlined
                              filled
                              readonly
                              :items="[
                                { text: 'Si', value: true },
                                { text: 'No', value: false },
                              ]"
                              item-text="text"
                              item-value="value"
                            >
                            </v-select>
                          </div>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary darken-1"
                          text
                          @click="closeDialog"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary darken-1"
                          text
                          @click="savecostItem"
                        >
                          <v-icon> mdi-floppy</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="800px">
                    <v-card>
                      <v-card-title class="text"
                        >¿Esta seguro que desea eliminar "{{
                          deleteDialogTitle
                        }}"?</v-card-title
                      >
                      <v-card-subtitle
                        >Para eliminar una categoria del tipo Elemento de Costo,
                        antes debe asegurarse que no se encuentra en uso tanto
                        en <strong>Campañas</strong> como
                        <strong>Borradores</strong>.</v-card-subtitle
                      >
                      <v-card-text>
                        <v-container>
                          <div
                            class="
                              d-flex
                              justify-center
                              pa-1
                              flex-column flex-lg-row flex-xl-row
                            "
                          >
                            <v-text-field
                              label="Nombre de elemento de costo"
                              dense
                              filled
                              readonly
                              outlined
                              class="mr-2"
                              v-model="costItemToDelete.name"
                            >
                            </v-text-field>
                            <v-text-field
                              label="Unidad de medida"
                              dense
                              filled
                              readonly
                              outlined
                              class="mr-2"
                              v-model="costItemToDelete.unitMeasure"
                            >
                            </v-text-field>
                            <v-text-field
                              label="Familia de Elemento de Costo"
                              dense
                              filled
                              readonly
                              outlined
                              class="mr-2"
                              type="text"
                              v-model="costItemToDelete.costItemFamilyName"
                            >
                            </v-text-field>
                            wip
                            <v-text-field
                              label="Impuesto (0-100)%"
                              dense
                              filled
                              append-icon="mdi-percent-outline"
                              outlined
                              readonly
                              class="mr-2"
                              type="number"
                              v-model.number="costItemToDelete.tax"
                            >
                            </v-text-field>
                          </div>
                          <div>
                            <v-select
                              label="Variantes Regionales"
                              v-model="costItemToDelete.hasRegionVariants"
                              class="mr-2"
                              dense
                              outlined
                              filled
                              readonly
                              :items="[
                                { text: 'Si', value: true },
                                { text: 'No', value: false },
                              ]"
                              item-text="text"
                              item-value="value"
                            >
                            </v-select>

                            <v-select
                              label="Variantes de Cultivo"
                              v-model="costItemToDelete.hasActivityVariants"
                              class="mr-2"
                              dense
                              outlined
                              filled
                              readonly
                              :items="[
                                { text: 'Si', value: true },
                                { text: 'No', value: false },
                              ]"
                              item-text="text"
                              item-value="value"
                            >
                            </v-select>
                          </div>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary darken-1"
                          text
                          @click="closeDelete"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="primary darken-1"
                          text
                          @click="deleteItemConfirm"
                        >
                          Eliminar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
            </v-data-table>
            <div
              class="
                d-flex
                justify-center
                flex-column flex-lg-row flex-xl-row
                py-5
              "
            >
              <v-text-field
                label="Nombre"
                dense
                class="mr-2"
                v-model="newSliceItem.name"
              >
              </v-text-field>

              <v-select
                label="Unidad de Medida"
                v-model="newSliceItem.unitMeasure"
                dense
                class="mr-2"
                denseListedCostItems
                :value="{ value: 'kg', name: 'kg' }"
                :items="[
                  { value: 'kg', name: 'kg' },
                  { value: 'ha', name: 'ha' },
                  { value: 'litro', name: 'litro' },
                  { value: 'bolsa', name: 'bolsa' },
                  { value: 'dosis', name: 'dosis' },
                ]"
                item-text="name"
                item-value="value"
              >
              </v-select>
              <v-text-field
                label="Impuesto (0-100)%"
                dense
                class="mr-2"
                append-icon="mdi-percent-outline"
                type="number"
                v-model.number="newSliceItem.tax"
              >
              </v-text-field>
              <v-text-field
                label="Familia de Elemento de Costo"
                dense
                filled
                readonly
                outlined
                hide-details
                class="mr-2 mb-5"
                type="text"
                :value="family.name"
              >
              </v-text-field>
              <v-select
                label="Variantes Regionales"
                v-model="newSliceItem.hasRegionVariants"
                class="mr-2"
                dense
                :items="[
                  { text: 'Si', value: true },
                  { text: 'No', value: false },
                ]"
                item-text="text"
                item-value="value"
              >
              </v-select>

              <v-select
                label="Variantes de Cultivo"
                v-model="newSliceItem.hasActivityVariants"
                class="mr-2"
                dense
                :items="[
                  { text: 'Si', value: true },
                  { text: 'No', value: false },
                ]"
                item-text="text"
                item-value="value"
              >
              </v-select>

              <v-btn
                color="primary white--text"
                class="ml-2"
                @click="saveNewSliceCostItem(family)"
                depressed
              >
                <v-icon> mdi-floppy </v-icon></v-btn
              >
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
directSliceCostItems
<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import { postRequest } from "@/managrx/serverCall";
import { deleteRequest, putRequest } from "../managrx/serverCall";
import {
  obsoleteCostItemFamilyId,
  toSkipCostItemFamilyIds,
} from "../managrx/constants";
export default {
  //
  name: "CostItems",
  computed: {
    ...mapGetters(["costItemFamilies", "directSliceCostItems"]),
    costItemFamiliesSorted() {
      return [...this.costItemFamiliesFiltered].sort(
        (a, b) => a.naturalOrder - b.naturalOrder
      );
    },
    costItemTaxAsDecimal() {
      // (0-100) -> (0-1)
      return this.costItem.tax / 100;
    },
    newSliceItemAsDecimal() {
      // (0-100) -> (0-1)
      return this.newSliceItem.tax / 100;
    },
    filteredDirectSliceCostItems() {
      let items = this.directSliceCostItems.filter((costItem) =>
        costItem.name.toLowerCase().startsWith(this.searchText.toLowerCase())
      );
      // this is to show tax in a scale of (0-100)
      return items.map((el) => {
        return { ...el, tax: el?.tax * 100 };
      });
    },
    costItemFamiliesFiltered() {
      return this.costItemFamilies.filter(
        (el) =>
          !obsoleteCostItemFamilyId.includes(el.id) &&
          !toSkipCostItemFamilyIds.includes(el.id)
      );
    },
  },

  mounted() {
    this.$store.dispatch("getAllCostItems");
  },
  methods: {
    editItem(item) {
      this.dialogTitle = item.name;
      this.costItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.deleteDialogTitle = item.name;
      this.costItemToDelete = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    async deleteItemConfirm() {
      let id = this.costItemToDelete.id;
      let response = await deleteRequest(`/direct_slice_cost_item/${id}`);
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.costItemToDelete = {};
        this.$store.dispatch("getAllCostItems");
      }
      this.closeDelete();
    },
    async savecostItem() {
      let id = this.costItem.id;
      let payload = {
        name: this.costItem.name,
        tax: this.costItemTaxAsDecimal,
        unitMeasure: this.costItem.unitMeasure,
      };
      let response = await putRequest(`/direct_slice_cost_item/${id}`, payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.dialog = false;
        this.costItem = {};
        this.$store.dispatch("getAllCostItems");
      }
    },
    navigateToCostItem(e) {
      this.$router.push({
        path: `/direct-slice-cost-item/${e.id}`,
      });
    },
    async saveNewSliceCostItem(family) {
      this.newSliceItem.costItemFamilyId = family.id;
      let payload = {
        ...this.newSliceItem,
        tax: this.newSliceItemAsDecimal,
      };
      let response = await postRequest("/direct_slice_cost_item", payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.newSliceItem = {};
        this.$store.dispatch("getAllCostItems");
      }
    },

    async saveNewFamily() {
      let payload = {
        ...this.newFamily,
      };

      let response = await postRequest("/cost_item_family", payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.newFamily = {
          name: "",
          naturalOrder: 0,
          autofinanciable: 1,
        };
        this.$store.dispatch("getAllCostItems");
      }
    },
  },
  data() {
    return {
      tab: 0,
      searchText: "",
      newFamily: {
        name: "",
        naturalOrder: 0,
        autofinanciable: 1,
      },
      newOtherTonItem: {},
      costItemToDelete: {},
      costItem: {},
      dialog: false,
      dialogTitle: "",
      deleteDialogTitle: "",
      dialogDelete: false,
      newSliceItem: {},
      newCampaignItem: {},
      costItemHeaders: [
        { text: "Nombre", value: "name" },
        { text: "Unidad de Medida", value: "unitMeasure" },
        { text: "Impuesto", value: "tax" },
        {
          text: "Familia de Elemento de Costo",
          value: "costItemFamilyName",
        },

        { text: "", value: "actions", sortable: false },
      ],
    };
  },
};
</script>
<style scoped>
.muted-text {
  opacity: 0.5;
}
</style>
